import React from "react";
import logo from "../../logo.gif";
import styles from "./Loading.module.scss";
import { useIsApiInteracting } from "../../hooks/useIsApiInteracting"

const Loading = ({ forceDisplay, children }) => {
  const isApiInteracting = useIsApiInteracting();

  if (!isApiInteracting && !forceDisplay) {
    return null;
  }

  return (
    <div className={styles.pulseWrapper}>
      <img className={styles.pulse} src={logo} alt="Carper Creative" />
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default Loading;
