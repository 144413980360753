import React from "react";
import Optional from "./Optional";
import Required from "./Required";

const Badge = ({ required, ...props }) => {
  if (required) {
    return <Required {...props} />;
  }

  return <Optional {...props} />;
};

export default Badge;
