import React from "react";
import Badge from "react-bootstrap/Badge";

const Optional = ({ className, ...props }) => {
  return (
    <Badge variant="primary" {...props} className={`ml-2 ${className}`}>
      optional
    </Badge>
  );
};

export default Optional;
