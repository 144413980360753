import React from "react";
import ReactDOM from "react-dom";
import { loadProgressBar } from "axios-progress-bar";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootElement = "root";

loadProgressBar({ showSpinner: true, parent: `#${rootElement}` });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(rootElement)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
