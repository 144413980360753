import React, { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
// import { faTimes } from "@fortawesome/free-regular-svg-icons";

const Required = ({ className, populated, ...props }) => {
  const variant = useMemo(
    () => !!populated ? 'success' : 'danger',
    [populated]
  );

  const showPopulated = useMemo(
    () => typeof populated !== "undefined",
    [populated]
  );

  return (
    <Badge variant={variant} {...props} className={`ml-2 ${className}`}>
      {showPopulated && (
        <FontAwesomeIcon className="pr-1" icon={populated ? faCheck : faTimes} />
      )}
      <span>required</span>
    </Badge >
  );
};

export default Required;
