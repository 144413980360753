import React from "react";
import { Container } from "react-bootstrap";
import styles from "./Footer.module.scss";
import useStreamerMode from "../../hooks/useStreamerMode";

const Footer = () => {
  const { streamerMode } = useStreamerMode();

  if (streamerMode) {
    return <div hidden />;
  }

  return (
    <footer className={styles.footer}>
      <Container className="text-muted">
        <div className="w-100">
          <div className="float-left text-left">
            Sam Albon | 1000069474 Ontario Inc. © {new Date().getFullYear()}
          </div>
          <div className="float-right text-right">
            <a
              href="https://carpercreative.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Carper Creative
            </a>
            <span> | Innovation in Brand Engagement</span>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
